interface IBusinessType {
  id: string
  name: string
}

interface IJurisdictionType {
  id: string
  name: string
}

interface States {
  codeCategory: string
  codeKey: string
  description: string
}

interface CommonSelectTootip {
  value: any
  label: string
  description: string
  icon?: string
  color?: string
}

interface CommonValue {
  value: any
  label: string
}

interface Country {
  codeCountry: string
  description: string
}

interface Category {
  codeCategory: string
  description: string
}

const CountriesData: Country[] = [
  { codeCountry: 'US', description: 'United States' },
  { codeCountry: 'CA', description: 'Canada' },
]

const AccountTypeData: CommonValue[] = [
  { value: 'Checking', label: 'Checking' },
  { value: 'Saving', label: 'Saving' },
]

const DefaultStatus: CommonValue[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

const AlternativeStatus: CommonValue[] = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'disabled', label: 'Disabled' },
]

const CategoriesData: Category[] = [
  { codeCategory: 'Seller', description: 'Seller' },
  { codeCategory: 'Buyer', description: 'Buyer' },
  { codeCategory: 'Agency', description: 'Agency' },
  { codeCategory: 'Servicer', description: 'Servicer' },
]

const CanadaStatesData = [
  { codeCategory: 'CA-PROVINCE', codeKey: 'AB', description: ' Alberta' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'BC',
    description: ' British Columbia',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'MB', description: ' Manitoba' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NB', description: ' New Brunswick' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'NL',
    description: ' Newfoundland and Labrador',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NS', description: ' Nova Scotia' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'NT',
    description: ' Northwest Territories',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NU', description: ' Nunavut' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'ON', description: ' Ontario' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'PE',
    description: ' Prince Edward Island',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'QC', description: ' Quebec' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'SK', description: ' Saskatchewan' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'YT', description: ' Yukon' },
]

const EUAStateData = [
  { codeCategory: 'US-STATE', codeKey: 'AK', description: 'Alaska' },
  { codeCategory: 'US-STATE', codeKey: 'AL', description: 'Alabama' },
  { codeCategory: 'US-STATE', codeKey: 'AR', description: 'Arkansas' },
  { codeCategory: 'US-STATE', codeKey: 'AZ', description: 'Arizona' },
  { codeCategory: 'US-STATE', codeKey: 'CA', description: 'California' },
  { codeCategory: 'US-STATE', codeKey: 'CO', description: 'Colorado' },
  { codeCategory: 'US-STATE', codeKey: 'CT', description: 'Connecticut' },
  {
    codeCategory: 'US-STATE',
    codeKey: 'DC',
    description: 'District of Columbia',
  },
  { codeCategory: 'US-STATE', codeKey: 'DE', description: 'Delaware' },
  { codeCategory: 'US-STATE', codeKey: 'FL', description: 'Florida' },
  { codeCategory: 'US-STATE', codeKey: 'GA', description: 'Georgia' },
  { codeCategory: 'US-STATE', codeKey: 'HI', description: 'Hawaii' },
  { codeCategory: 'US-STATE', codeKey: 'IA', description: 'Iowa' },
  { codeCategory: 'US-STATE', codeKey: 'ID', description: 'Idaho' },
  { codeCategory: 'US-STATE', codeKey: 'IL', description: 'Illinois' },
  { codeCategory: 'US-STATE', codeKey: 'IN', description: 'Indiana' },
  { codeCategory: 'US-STATE', codeKey: 'KS', description: 'Kansas' },
  { codeCategory: 'US-STATE', codeKey: 'KY', description: 'Kentucky' },
  { codeCategory: 'US-STATE', codeKey: 'LA', description: 'Louisiana' },
  { codeCategory: 'US-STATE', codeKey: 'MA', description: 'Massachusetts' },
  { codeCategory: 'US-STATE', codeKey: 'MD', description: 'Maryland' },
  { codeCategory: 'US-STATE', codeKey: 'ME', description: 'Maine' },
  { codeCategory: 'US-STATE', codeKey: 'MI', description: 'Michigan' },
  { codeCategory: 'US-STATE', codeKey: 'MN', description: 'Minnesota' },
  { codeCategory: 'US-STATE', codeKey: 'MO', description: 'Missouri' },
  { codeCategory: 'US-STATE', codeKey: 'MS', description: 'Mississippi' },
  { codeCategory: 'US-STATE', codeKey: 'MT', description: 'Montana' },
  { codeCategory: 'US-STATE', codeKey: 'NC', description: 'North Carolina' },
  { codeCategory: 'US-STATE', codeKey: 'ND', description: 'North Dakota' },
  { codeCategory: 'US-STATE', codeKey: 'NE', description: 'Nebraska' },
  { codeCategory: 'US-STATE', codeKey: 'NH', description: 'New Hampshire' },
  { codeCategory: 'US-STATE', codeKey: 'NJ', description: 'New Jersey' },
  { codeCategory: 'US-STATE', codeKey: 'NM', description: 'New Mexico' },
  { codeCategory: 'US-STATE', codeKey: 'NV', description: 'Nevada' },
  { codeCategory: 'US-STATE', codeKey: 'NY', description: 'New York' },
  { codeCategory: 'US-STATE', codeKey: 'OH', description: 'Ohio' },
  { codeCategory: 'US-STATE', codeKey: 'OK', description: 'Oklahoma' },
  { codeCategory: 'US-STATE', codeKey: 'OR', description: 'Oregon' },
  { codeCategory: 'US-STATE', codeKey: 'PA', description: 'Pennsylvania' },
  { codeCategory: 'US-STATE', codeKey: 'RI', description: 'Rhode Island' },
  { codeCategory: 'US-STATE', codeKey: 'SC', description: 'South Carolina' },
  { codeCategory: 'US-STATE', codeKey: 'SD', description: 'South Dakota' },
  { codeCategory: 'US-STATE', codeKey: 'TN', description: 'Tennessee' },
  { codeCategory: 'US-STATE', codeKey: 'TX', description: 'Texas' },
  { codeCategory: 'US-STATE', codeKey: 'UT', description: 'Utah' },
  { codeCategory: 'US-STATE', codeKey: 'VA', description: 'Virginia' },
  { codeCategory: 'US-STATE', codeKey: 'VT', description: 'Vermont' },
  { codeCategory: 'US-STATE', codeKey: 'WA', description: 'Washington' },
  { codeCategory: 'US-STATE', codeKey: 'WI', description: 'Wisconsin' },
  { codeCategory: 'US-STATE', codeKey: 'WV', description: 'West Virginia' },
  { codeCategory: 'US-STATE', codeKey: 'WY', description: 'Wyoming' },
]

const StatusData = [
  {
    value: 'Approved',
    label: 'Approved',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
  {
    value: 'Terminated',
    label: 'Terminated',
  },
]

const BusinessStatusData = [
  {
    value: 'Approved',
    label: 'Accepted',
  },
  {
    value: 'Application In Process',
    label: 'Application In Process',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
  {
    value: 'Terminated',
    label: 'Terminated',
  },
  {
    value: 'Terminated - Out of Business',
    label: 'Terminated - Out of Business',
  },
]

const MilitaryCertificates = [
  { label: 'No Certificates', value: 0 },
  { label: 'All Certificates', value: 1 },
  { label: 'Active Duty Only', value: 2 },
  { label: 'Non-Active Duty Only', value: 3 },
]

interface IStatesStategy {
  [field: string]: any
  US: States[]
  CA: States[]
}

const StatesStategy: IStatesStategy = {
  US: EUAStateData,
  CA: CanadaStatesData,
}

const BusinessType: IBusinessType[] = [
  { id: 'Agency', name: 'Agency' },
  { id: 'Buyer', name: 'Buyer' },
  { id: 'Seller', name: 'Seller' },
  { id: 'Servicer', name: 'Servicer' },
]

const JurisdictionType: IJurisdictionType[] = [
  { id: 'City', name: 'City' },
  { id: 'Country', name: 'Country' },
  { id: 'County', name: 'County' },
  { id: 'Province', name: 'Province' },
  { id: 'State', name: 'State' },
]

const BuyerType: CommonSelectTootip[] = [
  {
    label: 'Active',
    value: 'Active',
    description: 'This buyer is active on the platform.',
  },
  {
    label: 'Passive',
    value: 'Passive',
    description: 'This buyer is on the platform, but does not actively engage.',
  },
]

const AgenciesCertified: CommonSelectTootip[] = [
  {
    label: 'Agencies DT Certified | Provided to EverChain',
    value: 2,
    description:
      'Collection Agency identity has been provided to EverChain and Agencies have been EverChain certified.',
    icon: 'check',
    color: '#388e3c',
  },
  {
    label: 'Not DT Certified | Will Provide to Seller',
    value: 1,
    description:
      'Collection Agency identity has not been provided to EverChain nor have agencies been EverChain certified. Buyer is willing to provide the identity of the Collection Agencies to the Seller.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not DT Certified | Not Provided to EverChain/Seller',
    value: 0,
    description:
      'Collection Agency identity has not been provided to EverChain nor have Agencies been EverChain certified. Buyer is unwilling to provide the identity of the Collection Agencies to the Seller.',
    icon: 'close',
    color: 'red',
  },
]

const BackgroundChecks: CommonSelectTootip[] = [
  {
    label: 'DT Can Perform Background Checks',
    value: 2,
    description:
      "Permission to perform background checks on the organization's principals has been granted to EverChain.",
    icon: 'check',
    color: '#388e3c',
  },
  {
    label: 'May Allow Seller to Perform Background Checks',
    value: 1,
    description:
      "Permission to perform background checks on the organization's principals has not been granted to EverChain but may be to Seller.",
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'No Background Checks',
    value: 0,
    description:
      "Permission to perform background checks on the organization's principals will not be granted.",
    icon: 'close',
    color: 'red',
  },
]

const LicenseAttestation: CommonSelectTootip[] = [
  {
    label: 'Available | Provided to EverChain',
    value: 2,
    description:
      'Item has been provided to EverChain and is contained within the Due Diligence package.',
    icon: 'check',
    color: '#388e3c',
  },
  {
    label: 'Available from Buyer | Not Provided to EverChain',
    value: 1,
    description:
      'Item has not been provided to EverChain but Buyer is willing to provide directly to the Seller.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not Available | Not Provided to EverChain',
    value: 0,
    description:
      'Item has not been provided to EverChain nor is the Buyer willing to provide directly to the Seller.',
    icon: 'close',
    color: 'red',
  },
]

const FinancialStatements: CommonSelectTootip[] = [
  {
    label: 'Available | Provided to EverChain',
    value: 2,
    description:
      'Financials are available in EverChain and can be downloaded from the platform.',
    icon: 'check',
    color: '#388e3c',
  },
  {
    label: 'Available from Buyer | Not Provided to EverChain',
    value: 1,
    description:
      'Financials are not available in EverChain, but the buyer will provide them directly to the Seller upon request.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not Available | Not Provided to EverChain',
    value: 0,
    description:
      'Financials are not available in EverChain nor will the buyer provide them to the Seller.',
    icon: 'close',
    color: 'red',
  },
]

const DisclosureAuthorizationFillable = ['Disclosure & Authorization']
const BusinessInformationFillable = ['Business Information', 'Bank Account']
const SupportingDocumentsFillable = ['Executives']
const ComplianceFillable = [
  'Compliance (Legal & Regulatory)',
  'Company Registrations & Trading Names',
]
const SecurityAndDataFillable = ['Information Security']
const InsuranceFillable = ['Insurance']
const ReferencesFillable = ['References']
const ActiveBuyerFillable = ['Compliance Request']

const FillableForms: CommonValue[] = [
  {
    label: 'Disclosure & Authorization',
    value: 'disclosure-authorization',
  },
  {
    label: 'Business Information',
    value: 'business-information',
  },
  {
    label: 'Bank Account',
    value: 'bank-account',
  },
  {
    label: 'Executives',
    value: 'executives',
  },
  {
    label: 'Compliance (Legal & Regulatory)',
    value: 'compliance-legal-regulatory',
  },
  {
    label: 'Company Registrations & Trading Names',
    value: 'company-registrations-trading-names',
  },
  {
    label: 'Information Security',
    value: 'information-security',
  },
  {
    label: 'Insurance',
    value: 'insurance',
  },
  {
    label: 'References',
    value: 'references',
  },
  {
    label: 'Compliance Request',
    value: 'compliance-request',
  },
  {
    label: 'Digital Survey',
    value: 'digital-survey',
  },
  {
    label: 'Portfolio / Asset Type',
    value: 'portfolio-asset-types',
  },
  {
    label: 'Media Settings',
    value: 'media-settings',
  },
]

const Departments: CommonValue[] = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Business-Development', label: 'Business Development' },
  { value: 'Compliance', label: 'Compliance' },
  { value: 'Debt-Strategies', label: 'Debt Strategies' },
  { value: 'Development', label: 'Development' },
  { value: 'Human-Resources', label: 'Human Resources' },
  { value: 'Product', label: 'Product' },
  { value: 'Recovery-Success', label: 'Recovery Success' },
]

class HardCoreData {
  private businessType = BusinessType
  private jurisdictionType = JurisdictionType
  private states = StatesStategy
  private status = StatusData
  private countries = CountriesData
  private category = CategoriesData
  private accountType = AccountTypeData
  private defaultStatus = DefaultStatus
  private militaryCertificates = MilitaryCertificates
  private businessStatus = BusinessStatusData
  private buyerType = BuyerType
  private agenciesCertified = AgenciesCertified
  private backgroundChecks = BackgroundChecks
  private licenceAttetation = LicenseAttestation
  private financialStatements = FinancialStatements
  private alternativeStatus = AlternativeStatus
  private disclosureAuthorizationFillable = DisclosureAuthorizationFillable
  private activeBuyerFillable = ActiveBuyerFillable
  private referencesFillable = ReferencesFillable
  private insuranceFillable = InsuranceFillable
  private securityAndDataFillable = SecurityAndDataFillable
  private complianceFillable = ComplianceFillable
  private businessInformationFillable = BusinessInformationFillable
  private supportingDocumentsFillable = SupportingDocumentsFillable
  private fillableForms = FillableForms
  private departments = Departments

  getFillableDocuments(category: string): string[] {
    switch (category) {
      case 'Disclosure & Authorization':
        return this.disclosureAuthorizationFillable
      case 'Active Buyers':
        return this.activeBuyerFillable
      case 'References':
        return this.referencesFillable
      case 'Insurance':
        return this.insuranceFillable
      case 'Security + Data':
        return this.securityAndDataFillable
      case 'Legal + Regulatory':
        return this.complianceFillable
      case 'Supporting Documents':
        return this.supportingDocumentsFillable
      case 'Business Information':
        return this.businessInformationFillable
      default:
        return []
    }
  }

  getDepartments(): CommonValue[] {
    return this.departments
  }

  getFillableForms(): CommonValue[] {
    return this.fillableForms
  }

  getBusinessType(): IBusinessType[] {
    return this.businessType
  }

  getJurisdictionType(): IJurisdictionType[] {
    return this.jurisdictionType
  }

  getAlternativeStatus(): CommonValue[] {
    return this.alternativeStatus
  }

  getFinancialStatements(): CommonSelectTootip[] {
    return this.financialStatements
  }
  getLicenseAttestation(): CommonSelectTootip[] {
    return this.licenceAttetation
  }
  getBackgroundChecks(): CommonSelectTootip[] {
    return this.backgroundChecks
  }
  getAgenciesCertified(): CommonSelectTootip[] {
    return this.agenciesCertified
  }

  getBuyerType(): CommonSelectTootip[] {
    return this.buyerType
  }

  getCountries(): Country[] {
    return this.countries
  }

  getCategories(): Category[] {
    return this.category
  }

  getStates(type?: string | 'US' | 'CA' | undefined): States[] {
    return this.states[type || 'US'] || []
  }

  getDefaultStatus(): CommonValue[] {
    return this.defaultStatus
  }

  getStatus(): CommonValue[] {
    return this.status
  }

  getAccountType(): CommonValue[] {
    return this.accountType
  }

  getMilitaryCertificates(): CommonValue[] {
    return this.militaryCertificates
  }

  getBusinessStatus(): CommonValue[] {
    return this.businessStatus
  }
}

export default new HardCoreData()
