import { httpClient } from 'src/api/axios/api'
import { archiveSOP } from './urls'

export const archiveSOPFile = async (
  id: string,
  reason: string
): Promise<any> => {
  const response = await httpClient.post(archiveSOP, {
    id: Number(id),
    reason,
  })
  return response?.data
}
