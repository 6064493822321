import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import axios from 'axios'
import { MaxFileSize } from 'src/utils/constants'
import { Delete, Description } from '@material-ui/icons'
import { UploadItem } from 'src/components/UploadDragDrop/styles'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { DialogTitleStyled } from 'src/layouts/Main/styles'
import CloseIcon from '@material-ui/icons/Close'
import { DataValidation, ParseXlsxFile } from 'src/utils/parseFile'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useQueryClient } from 'react-query'

interface IUploadTemplateInfo {
  businessUploadRequest: number
}

export interface ColumnMetadata {
  name: string
  type: string
  isRequired: boolean
  length: number
}

interface IUploadTemplate {
  businessUploadRequest: number
  openDialog: boolean
  close: () => void
}

const UploadTemplate: React.FC<IUploadTemplate> = ({
  close,
  businessUploadRequest,
  openDialog,
}: IUploadTemplate) => {
  const MAX_SIZE_FILE = MaxFileSize

  const userToken = GetAccessToken()
  const { enqueueSnackbar } = useSnackbar()
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const notistackSucces = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const queryClient = useQueryClient()
  const [saving, setSaving] = useState(false)

  const [file, setFile] = useState<File | null>(null)

  const [initialValues, setInitialValues] = useState<IUploadTemplateInfo>({
    businessUploadRequest,
  })

  const GetFileHeaders = () => {
    const headers: ColumnMetadata[] = [
      {
        name: 'Id',
        type: 'number',
        isRequired: true,
        length: 8,
      },
      {
        name: 'Approve (Y/N)',
        type: 'string',
        isRequired: true,
        length: 2,
      },
      {
        name: 'Expiration Date',
        type: 'date',
        isRequired: false,
        length: 20,
      },
      {
        name: 'Reject Reason',
        type: 'string',
        isRequired: false,
        length: 400,
      },
    ]

    return headers
  }

  const handleUploadFile = async (fileUploaded: any) => {
    const columnsMetadata = GetFileHeaders()
    const result = await ParseXlsxFile(fileUploaded, columnsMetadata)

    const { errors, dataConverted } = DataValidation(
      result.data,
      columnsMetadata,
      country
    )

    return dataConverted
  }

  const SubmitForm = async () => {
    if (file) {
      const result = await handleUploadFile(file)

      setSaving(true)
      axios({
        method: 'post',
        url: `${BACKEND_URL}/businessUpload/ApproveFile`,
        headers: {
          Authorization: userToken,
        },
        data: {
          files: JSON.stringify(result),
          businessUploadRequest: businessUploadRequest.toString(),
        },
      })
        .then(() => {
          enqueueSnackbar('Template uploaded.', notistackSucces)
          setFile(null)

          queryClient.refetchQueries({
            queryKey: 'documentRequest',
          })

          queryClient.refetchQueries({
            queryKey: 'getfilesUploaded',
          })

          queryClient.refetchQueries({
            queryKey: 'getHistory',
          })

          queryClient.refetchQueries({
            queryKey: 'uploadProgressPercent',
          })
          queryClient.refetchQueries({
            queryKey: 'uploadedProgressPercent',
          })

          queryClient.refetchQueries({
            queryKey: 'getAdditionalDocuments',
          })

          setSaving(false)
          close()
        })
        .catch((error) => {
          enqueueSnackbar(
            'Error on uploading template.',
            notistackOptions('error')
          )
          setSaving(false)
        })
    }
  }

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0])
  }

  const handleUploadFileRemove = () => {
    setFile(null)
  }

  return (
    <Dialog open={openDialog} onClose={() => close()} maxWidth="md">
      <DialogTitleStyled id="dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          Upload Template
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => close()}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent>
        <Box width="100%" minWidth="480px">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {file && file !== null && (
                <UploadItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <List
                        style={{
                          marginBottom: 2,
                          width: '100%',
                        }}
                      >
                        <ListItem alignItems="flex-start" key={`item_${0}`}>
                          <div className="upload-item-info" key={`div_${0}`}>
                            <Description
                              key={`DescriptionIcon_${0}`}
                              fontSize="small"
                              color="primary"
                              className="upload-item-icon"
                            />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="span"
                              key={`Typography_${file?.name}`}
                            >
                              {file && file?.name?.length > 200
                                ? `${file?.name.substring(0, 15)}...`
                                : file?.name}
                            </Typography>
                            <IconButton
                              size="small"
                              key={`Button_${0}`}
                              onClick={() => {
                                handleUploadFileRemove()
                              }}
                            >
                              <Delete
                                key={`DeleteButton_${0}`}
                                className="upload-item-icon"
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </UploadItem>
              )}
              {file === null && (
                <UploadDragDrop
                  linkText="Upload File"
                  text=" Excel files only (max. 200mb)"
                  size="small"
                  multiple={false}
                  onDrop={(acceptedFiles: any) => {
                    handleDroppedFiles(acceptedFiles)
                  }}
                  maxSize={MAX_SIZE_FILE}
                  onDropRejected={handleFileRejected}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button
                  disabled={file === null || saving}
                  data-cy="load-sop-button"
                  variant="contained"
                  color="primary"
                  title="Upload"
                  onClick={SubmitForm}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UploadTemplate
