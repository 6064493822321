import { CORPORATE_SOP_DETAIL } from 'src/routes'
import {
  renderClickableNumberLink,
  renderDate,
  renderSOPStatus,
} from 'src/utils/formatKendoColumns'

export const getColumns = (cardType: string) => {
  const columnsToRetrieve: any[] = []

  columnsToRetrieve.push(
    {
      field: 'id',
      title: 'SOP ID',
      width: '120',
      show: false,
    },
    {
      field: 'name',
      title: 'SOP Name',
      show: true,
      cell: (props: any) =>
        renderClickableNumberLink(
          props,
          `${CORPORATE_SOP_DETAIL}/${props.dataItem['id']}/${cardType}`
        ),
    },
    {
      field: 'department',
      title: 'Department',
      show: true,
    },
    {
      field: 'owner',
      title: 'Owner',
      show: true,
    },
    {
      field: 'createDateUtc',
      title: 'Created Date',
      cell: renderDate,
      show: ['master'].includes(cardType),
      filter: 'date',
    },
    {
      field: 'lastUpdatedDateUtc',
      title: 'Approved Date',
      cell: renderDate,
      filter: 'date',
      show: ['master'].includes(cardType),
    },
    {
      field: 'status',
      title: 'Status',
      show: ['ready-for-redlines', 'final-review'].includes(cardType),
      filter: 'text',
      cell: renderSOPStatus,
    }
  )

  return columnsToRetrieve
}
